import React from 'react';
import { Layout, ConfigProvider } from 'antd';

import ptBR from 'antd/lib/locale-provider/pt_BR'
import { Provider } from 'react-redux';
import store from './state';
import AppHeader from './components/Header/Header';
import AppUnit from './components/Unit/Unit';
import './App.css';

const { Content } = Layout;

const App = () => (
  <ConfigProvider locale={ptBR}>
    <Provider store={store}>
      <Layout style={{ padding: '15px', width: '445px', margin: 'auto' }}>
        <AppHeader />
        <Content
          style={{
            minHeight: 'calc(100vh - 330px)',
            background: '#F1F5F8 0% 0% no-repeat padding-box',
            borderRadius: '20px',
            opacity: 1,
            marginTop: '-30px',
            padding: '20px'
          }}
        >
          <AppUnit />
        </Content>
      </Layout>
    </Provider>
  </ConfigProvider>
);

export default App;
