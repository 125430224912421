import React, { useEffect, Fragment, useState } from 'react';
import {Typography, Card, Button, Avatar, Row, Col} from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../actions';
import './unit.css';

const { Text, Title } = Typography;

const mapStateToProps = ({ components }) => ({ components });
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch)

const AppUnit = ({
  components,
}) => {
  const [units, setUnits] = useState([]);

  useEffect(() => {
    if (components.filter) {
      setUnits(components.units.filter((unit) => unit.status && unit.showWhatsApp && unit.city === components.filter))
    } else {
      setUnits(components.units.filter((unit) => unit.status && unit.showWhatsApp))
    }
  }, [components.filter, components.units])

  return (
    <Fragment>
      {
        units.map((unit) => (
          <Card
            className={'unit-item'}
            actions={[
              <Button
                target="_blanck"
                href={`https://api.whatsapp.com/send?phone=${unit.whatsApp}&text=Ol%C3%A1`}
                type="link"
              >
                <img
                  style={{
                    width: '300px',
                    height: '40px',
                  }}
                  src="whatsapp.png"
                  alt=""
                />
              </Button>
            ]}
            key={unit.email}
          >
            <Row justify='left' >
              <Col span={6}>
                <Avatar size={70} src={'monograma_sd.png'} />
              </Col>
              <Col span={18}>
                <Title level={4}>{unit.name}</Title>
                <Text>{unit.address}</Text>
                <br />
                <Text>{unit.city} - {unit.state}</Text>
              </Col>
            </Row>
          </Card>
        ))
      }
    </Fragment>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AppUnit);
