import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
})

api.interceptors.response.use(
  response => Promise.resolve(response.data),
  async error => {
    return Promise.reject(error)
  },
)

export default {
  get: api.get.bind(api),
  post: api.post.bind(api),
  put: api.put.bind(api),
  patch: api.patch.bind(api),
  delete: api.delete.bind(api),
}
