import {
  all,
  takeLatest,
  put,
  call
} from 'redux-saga/effects'

import api from '../services/api'
import { t } from 'ab18n'
import { actions, types } from './actions'
import errorsFormat from '../shared/errors-format'

function* fetchUnitsSaga() {
  yield put(actions.setLoadingUnits(true))
  try {
    const response = yield call(api.get, '/units?limit=-1')
    yield put(actions.setUnits(response._embedded.items))
  } catch (error) {
    errorsFormat(error, t('components.units.notification.error'))
  } finally {
    yield put(actions.setLoadingUnits(false))
  }
}

export default function* () {
  yield all([
    takeLatest(types.FETCH_UNITS, fetchUnitsSaga),
  ])
}
