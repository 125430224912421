const dictionary = {
  general: {
    loading: 'Carregando',
    logo: 'Sobrancelhas Design',
    abbreviationLogo: 'SD',
    notification: {
      error: 'Não foi possível carregar os dados.',
      pageNotFound: 'Página não encontrada',
    },
  },
  components: {
      units: {
        loading: 'Carregando...',
        placeholder: 'Selecione uma unidade',
        notification: {
          error: 'Erro ao carregar unidades',
        },
      },
    },
}

export default {
  locale: 'pt-BR',
  label: 'Português (Brasil)',
  country: 'BR',
  currency: 'BRL',
  dictionary,
}
