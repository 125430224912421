import { typeActions } from '../state/util'

const { actions, types } = typeActions('components', {
  fetchUnits: () => ({}),
  setUnits: (data = null, error = null) => ({ payload: data }),
  setFilter: value => ({ payload: value }),
  setLoadingUnits: value => ({ loading: value }),
})

export {
  actions,
  types,
}
