import { types } from './actions'

const INITIAL_STATE = {
  units: [],
  filter: null,
  meta: {
    loading: false
  },
}

export const components = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_LOADING_UNITS:
      return {
        ...state,
        ...action
      }

    case types.SET_UNITS:
      return {
        ...state,
        units: action.payload
      }

    case types.SET_FILTER:
      return {
        ...state,
        filter: action.payload
      }

    default:
      return state
  }
}
